import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/settings/shippings/';

function list() {
  return storeRequest.get(path);
}

function getListCountries() {
  return storeRequest.get('/stores/public/shippingzones/countries');
}

function get(id) {
  return storeRequest.get(path + id);
}

function create(shipping) {
  return storeRequest.post(path, shipping);
}

function update(shipping) {
  return storeRequest.put(path + shipping.id, shipping);
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}

export const shippingService = {
  list,
  create,
  update,
  get,
  deleteById,
  getListCountries,
};
