<template>
  <div class="shipping__page">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="" title="Settings" route="website.settings" />
      </v-col>
      <v-col cols="12" class="page-header">
        <h2 class="page-title">Shipping</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="mt-5">
        <v-row>
          <v-col cols="12" md="4">
            <h3>Shipping rates at checkout</h3>
            <p class="mt-3">
              Use shipping zones to define regions where you'll ship. Use shipping rates to define how much customers
              pay for shipping in those regions.
            </p>
          </v-col>
          <v-col cols="12" md="8" class="elevation-form px-5 py-5">
            <v-row>
              <v-col cols="12" class="d-flex align-center">
                <Notification type="error" :messages="error" v-if="error" />
                <h3>Shipping zones</h3>
                <router-link class="ml-auto router-link" :to="{ name: 'website.settings.shipping.create' }">
                  Add shipping zone
                </router-link>
              </v-col>
              <v-col cols="12" v-if="isLoading">
                <SkeletonLoaders type="card-heading, list-item-three-line" />
              </v-col>
              <v-col v-else cols="12" v-for="(shipping, index) in shippings" :key="index">
                <v-divider></v-divider>
                <div class="mt-5">
                  <div class="d-flex align-center">
                    <v-icon v-if="!shipping.countries" class="mr-3">fas fa-globe-americas</v-icon>
                    <v-img
                      v-if="shipping.countries && shipping.countries.find(c => c.code == 'US')"
                      class="mr-3"
                      max-width="50"
                      :src="require('@/assets/images/usa.svg')"
                    ></v-img>
                    <h3>{{ shipping.name }}</h3>
                    <router-link
                      class="ml-auto router-link"
                      :to="{ name: 'website.settings.shipping.update', params: { id: shipping.id } }"
                    >
                      Edit
                    </router-link>
                  </div>
                  <!-- Weight based rates -->
                  <div class="mt-3" v-for="weight in shipping.weightBasedShippingRate" :key="weight.name">
                    <div>{{ weight.name }}</div>
                    <div class="d-flex align-center">
                      <div>
                        {{ weight.weightLow }} {{ weight.weightUnit }} -
                        {{ weight.weightHigh == 0 ? 'and up' : weight.weightHigh }}
                        {{ weight.weightHigh != 0 ? weight.weightUnit : '' }}
                      </div>
                      <div class="ml-auto">{{ weight.price }} {{ currency.currencyCode() }}</div>
                    </div>
                  </div>
                  <!-- Price based rates -->
                  <div class="mt-3" v-for="priceBase in shipping.priceBasedShippingRate" :key="priceBase.name">
                    <div>{{ priceBase.name }}</div>
                    <div class="d-flex align-center">
                      <div>
                        {{ priceBase.minOrderSubtotal }} {{ currency.currencyCode() }} -
                        {{ priceBase.maxOrderSubtotal == 0 ? 'and up' : priceBase.maxOrderSubtotal }}
                        {{ priceBase.maxOrderSubtotal != 0 ? currency.currencyCode() : '' }}
                      </div>
                      <div class="ml-auto">{{ priceBase.price }} {{ currency.currencyCode() }}</div>
                    </div>
                  </div>
                  <!-- items based rates -->
                  <div class="mt-3" v-for="itemBase in shipping.itemBasedShippingRate" :key="itemBase.name">
                    <!-- <div>{{ itemBase.name }}</div> -->
                    <div>
                      <strong>Match all products</strong>
                    </div>
                    <div>
                      <strong v-if="itemBase.rules[0]">
                        {{ convertFilterDataName(itemBase.rules[0].column) }}
                        {{ convertFilterDataName(itemBase.rules[0].relation) }}
                        {{ convertFilterDataName(itemBase.rules[0].condition) }}
                      </strong>
                    </div>
                    <div class="d-flex align-center">
                      <div>First item</div>
                      <div class="ml-auto">{{ itemBase.firstItemPrice }} {{ currency.currencyCode() }}</div>
                    </div>
                    <div class="d-flex align-center">
                      <div>Per additional item</div>
                      <div class="ml-auto">{{ itemBase.additionalItemPrice }} {{ currency.currencyCode() }}</div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { shippingService } from '@/apis/shipping';
import convertName from '@/mixins/convert-data-name';
import event from '@/plugins/event-bus';
import currency from '@/helpers/currency';

export default {
  mixins: [convertName],
  data() {
    return {
      shippings: [],
      isLoading: false,
      error: null,
      currency,
    };
  },
  created() {
    this.loadShippings();
  },
  methods: {
    async loadShippings() {
      this.isLoading = true;
      try {
        let shippingData = await shippingService.list();
        this.shippings = shippingData.data;
        event.$emit('getStepStatus', message => {
          this.getStep();
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.error = error;
      }
    },
  },
};
</script>
